/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Link } from "react-router-dom";
// ---------------------------------------------------------
import "./Button.css";
// ---------------------------------------------------------

const Button = ({
  type = "button" || "link",
  target = "_top",
  link = "",
  onclick = () => {},
  otherClassNameBtn = "",
  title = "",
}) => {
  if (type === "link") {
    return (
      <Link
        to={link}
        target={target}
        rel="noreferrer"
        className={`btn_container ${otherClassNameBtn}`}
      >
        <p>{title}</p>
      </Link>
    );
  } else {
    return (
      <>
        {/* Class names starting with "btn" refer to the file: Button.css */}
        <button
          className={`btn_container ${otherClassNameBtn}`}
          onClick={onclick}
        >
          <p>{title}</p>
        </button>
      </>
    );
  }
};

export default Button;
