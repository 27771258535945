/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
// ---------------------------------------------------------

export const option_choose = "choose";

export const experienceOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "form-applicant-step-2-experience-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-step-2-experience-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-step-2-experience-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-step-2-experience-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-step-2-experience-option-5",
    value: "option-5",
  },
  {
    name: "form-applicant-step-2-experience-option-6",
    value: "option-6",
  },
  {
    name: "form-applicant-step-2-experience-option-7",
    value: "option-7",
  },
  {
    name: "form-applicant-step-2-experience-option-8",
    value: "option-8",
  },
  {
    name: "form-applicant-step-2-experience-option-9",
    value: "option-9",
  },
  {
    name: "form-applicant-step-2-experience-option-10",
    value: "option-10",
  },
  {
    name: "form-applicant-step-2-experience-option-11",
    value: "option-11",
  },
  {
    name: "form-applicant-step-2-experience-option-12",
    value: "option-12",
  },
  {
    name: "form-applicant-step-2-experience-option-13",
    value: "option-13",
  },
  {
    name: "form-applicant-step-2-experience-option-14",
    value: "option-14",
  },
  {
    name: "form-applicant-step-2-experience-option-15",
    value: "option-15",
  },
  {
    name: "form-applicant-step-2-experience-option-16",
    value: "option-16",
  },
  {
    name: "form-applicant-step-2-experience-option-17",
    value: "option-17",
  },
  {
    name: "form-applicant-step-2-experience-option-18",
    value: "option-18",
  },
  {
    name: "form-applicant-step-2-experience-option-19",
    value: "option-19",
  },
  {
    name: "form-applicant-step-2-experience-option-20",
    value: "option-20",
  },
  {
    name: "form-applicant-step-2-experience-option-21",
    value: "option-21",
  },
  {
    name: "form-applicant-step-2-experience-option-22",
    value: "option-22",
  },
  {
    name: "form-applicant-step-2-experience-option-23",
    value: "option-23",
  },
  {
    name: "form-applicant-step-2-experience-option-24",
    value: "option-24",
  },
  {
    name: "form-applicant-step-2-experience-option-25",
    value: "option-25",
  },
  {
    name: "form-applicant-step-2-experience-option-26",
    value: "option-26",
  },
  {
    name: "form-applicant-step-2-experience-option-27",
    value: "option-27",
  },
  {
    name: "form-applicant-step-2-experience-option-28",
    value: "option-28",
  },
  {
    name: "form-applicant-step-2-experience-option-29",
    value: "option-29",
  },
  {
    name: "form-applicant-step-2-experience-option-30",
    value: "option-30",
  },
  {
    name: "form-applicant-step-2-experience-option-31",
    value: "option-31",
  },
  {
    name: "form-applicant-step-2-experience-option-32",
    value: "option-32",
  },
  {
    name: "form-applicant-step-2-experience-option-33",
    value: "option-33",
  },
  {
    name: "form-applicant-step-2-experience-option-34",
    value: "option-34",
  },
  {
    name: "form-applicant-step-2-experience-option-35",
    value: "option-35",
  },
  {
    name: "form-applicant-step-2-experience-option-36",
    value: "option-36",
  },
  {
    name: "form-applicant-step-2-experience-option-37",
    value: "option-37",
  },
  {
    name: "form-applicant-step-2-experience-option-38",
    value: "option-38",
  },
  {
    name: "form-applicant-step-2-experience-option-39",
    value: "option-39",
  },
  {
    name: "form-applicant-step-2-experience-option-40",
    value: "option-40",
  },
  {
    name: "form-applicant-step-2-experience-option-41",
    value: "option-41",
  },
  {
    name: "form-applicant-step-2-experience-option-42",
    value: "option-42",
  },
  {
    name: "form-applicant-step-2-experience-option-43",
    value: "option-43",
  },
  {
    name: "form-applicant-step-2-experience-option-44",
    value: "option-44",
  },
  {
    name: "form-applicant-step-2-experience-option-45",
    value: "option-45",
  },
  {
    name: "form-applicant-step-2-experience-option-46",
    value: "option-46",
  },
  {
    name: "form-applicant-step-2-experience-option-47",
    value: "option-47",
  },
  {
    name: "form-applicant-step-2-experience-option-48",
    value: "option-48",
  },
  {
    name: "form-applicant-step-2-experience-option-49",
    value: "option-49",
  },
  {
    name: "form-applicant-step-2-experience-option-50",
    value: "option-50",
  },
  {
    name: "form-applicant-step-2-experience-option-51",
    value: "option-51",
  },
  {
    name: "form-applicant-step-2-experience-option-52",
    value: "option-52",
  },
  {
    name: "form-applicant-step-2-experience-option-53",
    value: "option-53",
  },
  {
    name: "form-applicant-step-2-experience-option-54",
    value: "option-54",
  },
  {
    name: "form-applicant-step-2-experience-option-55",
    value: "option-55",
  },
  {
    name: "form-applicant-step-2-experience-option-56",
    value: "option-56",
  },
  {
    name: "form-applicant-step-2-experience-option-57",
    value: "option-57",
  },
  {
    name: "form-applicant-step-2-experience-option-58",
    value: "option-58",
  },
  {
    name: "form-applicant-step-2-experience-option-59",
    value: "option-59",
  },
  {
    name: "form-applicant-step-2-experience-option-60",
    value: "option-60",
  },
  {
    name: "form-applicant-step-2-experience-option-61",
    value: "option-61",
  },
  {
    name: "form-applicant-step-2-experience-option-62",
    value: "option-62",
  },
  {
    name: "form-applicant-step-2-experience-option-63",
    value: "option-63",
  },
];

export const timeExpOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "form-applicant-step-3-time-exp-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-step-3-time-exp-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-step-3-time-exp-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-step-3-time-exp-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-step-3-time-exp-option-5",
    value: "option-5",
  },
  {
    name: "form-applicant-step-3-time-exp-option-6",
    value: "option-6",
  },
];

export const travelOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "form-applicant-step-3-travel-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-step-3-travel-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-step-3-travel-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-step-3-travel-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-step-3-travel-option-5",
    value: "option-5",
  },
];

export const lngPrefOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "form-applicant-step-3-lngpref-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-step-3-lngpref-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-step-3-lngpref-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-step-3-lngpref-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-step-3-lngpref-option-5",
    value: "option-5",
  },
  {
    name: "form-applicant-step-3-lngpref-option-6",
    value: "option-6",
  },
  {
    name: "form-applicant-step-3-lngpref-option-7",
    value: "option-7",
  },
  {
    name: "form-applicant-step-3-lngpref-option-8",
    value: "option-8",
  },
  {
    name: "form-applicant-step-3-lngpref-option-9",
    value: "option-9",
  },
  {
    name: "form-applicant-step-3-lngpref-option-10",
    value: "option-10",
  },
  {
    name: "form-applicant-step-3-lngpref-option-11",
    value: "option-11",
  },
  {
    name: "form-applicant-step-3-lngpref-option-12",
    value: "option-12",
  },
  {
    name: "form-applicant-step-3-lngpref-option-13",
    value: "option-13",
  },
  {
    name: "form-applicant-step-3-lngpref-option-14",
    value: "option-14",
  },
  {
    name: "form-applicant-step-3-lngpref-option-15",
    value: "option-15",
  },
  {
    name: "form-applicant-step-3-lngpref-option-16",
    value: "option-16",
  },
  {
    name: "form-applicant-step-3-lngpref-option-17",
    value: "option-17",
  },
  {
    name: "form-applicant-step-3-lngpref-option-18",
    value: "option-18",
  },
  {
    name: "form-applicant-step-3-lngpref-option-19",
    value: "option-19",
  },
  {
    name: "form-applicant-step-3-lngpref-option-20",
    value: "option-20",
  },
];

export const okOptions = [
  {
    name: "form-please-choose-option",
    value: option_choose,
  },
  {
    name: "form-applicant-ok-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-ok-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-ok-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-ok-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-ok-option-5",
    value: "option-5",
  },
];

export const adjetivesOptions = [
  {
    name: "form-applicant-step-2-adjective-option-1",
    value: "option-1",
  },
  {
    name: "form-applicant-step-2-adjective-option-2",
    value: "option-2",
  },
  {
    name: "form-applicant-step-2-adjective-option-3",
    value: "option-3",
  },
  {
    name: "form-applicant-step-2-adjective-option-4",
    value: "option-4",
  },
  {
    name: "form-applicant-step-2-adjective-option-5",
    value: "option-5",
  },
  {
    name: "form-applicant-step-2-adjective-option-6",
    value: "option-6",
  },
  {
    name: "form-applicant-step-2-adjective-option-7",
    value: "option-7",
  },
  {
    name: "form-applicant-step-2-adjective-option-8",
    value: "option-8",
  },
  {
    name: "form-applicant-step-2-adjective-option-9",
    value: "option-9",
  },
  {
    name: "form-applicant-step-2-adjective-option-10",
    value: "option-10",
  },
  {
    name: "form-applicant-step-2-adjective-option-11",
    value: "option-11",
  },
  {
    name: "form-applicant-step-2-adjective-option-12",
    value: "option-12",
  },
  {
    name: "form-applicant-step-2-adjective-option-13",
    value: "option-13",
  },
  {
    name: "form-applicant-step-2-adjective-option-14",
    value: "option-14",
  },
  {
    name: "form-applicant-step-2-adjective-option-15",
    value: "option-15",
  },
  {
    name: "form-applicant-step-2-adjective-option-16",
    value: "option-16",
  },
  {
    name: "form-applicant-step-2-adjective-option-17",
    value: "option-17",
  },
  {
    name: "form-applicant-step-2-adjective-option-18",
    value: "option-18",
  },
  {
    name: "form-applicant-step-2-adjective-option-19",
    value: "option-19",
  },
  {
    name: "form-applicant-step-2-adjective-option-20",
    value: "option-20",
  },
  {
    name: "form-applicant-step-2-adjective-option-21",
    value: "option-21",
  },
  {
    name: "form-applicant-step-2-adjective-option-22",
    value: "option-22",
  },
  {
    name: "form-applicant-step-2-adjective-option-23",
    value: "option-23",
  },
  {
    name: "form-applicant-step-2-adjective-option-24",
    value: "option-24",
  },
  {
    name: "form-applicant-step-2-adjective-option-25",
    value: "option-25",
  },
  {
    name: "form-applicant-step-2-adjective-option-26",
    value: "option-26",
  },
  {
    name: "form-applicant-step-2-adjective-option-27",
    value: "option-27",
  },
  {
    name: "form-applicant-step-2-adjective-option-28",
    value: "option-28",
  },
  {
    name: "form-applicant-step-2-adjective-option-29",
    value: "option-29",
  },
  {
    name: "form-applicant-step-2-adjective-option-30",
    value: "option-30",
  },
  {
    name: "form-applicant-step-2-adjective-option-31",
    value: "option-31",
  },
  {
    name: "form-applicant-step-2-adjective-option-32",
    value: "option-32",
  },
  {
    name: "form-applicant-step-2-adjective-option-33",
    value: "option-33",
  },
  {
    name: "form-applicant-step-2-adjective-option-34",
    value: "option-34",
  },
  {
    name: "form-applicant-step-2-adjective-option-35",
    value: "option-35",
  },
  {
    name: "form-applicant-step-2-adjective-option-36",
    value: "option-36",
  },
  {
    name: "form-applicant-step-2-adjective-option-37",
    value: "option-37",
  },
  {
    name: "form-applicant-step-2-adjective-option-38",
    value: "option-38",
  },
  {
    name: "form-applicant-step-2-adjective-option-39",
    value: "option-39",
  },
  {
    name: "form-applicant-step-2-adjective-option-40",
    value: "option-40",
  },
];
