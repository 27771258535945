/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import "./ContainerWithColumns.css";
// ---------------------------------------------------------

const ContainerWithColumns = ({
  otherClassNameContainer = "",
  children = <></>,
}) => {
  return (
    <>
      {/* Class names starting with "cwc" refer to the file: ContainerWithColumns.css */}
      <div className={`cwc_container ${otherClassNameContainer}`}>
        {children}
      </div>
    </>
  );
};

export default ContainerWithColumns;
