/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./Error404.css";
import { t } from "../../languages/settingsLanguages";
import ImgError404 from "../../assets/imgs/error-404.png";
import Button from "../../components/button/Button";
// ---------------------------------------------------------

const Error404 = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "e404" refer to the file: Error404.css */}
      <Helmet>
        <title>
          {docTitle} | {t("error-404-not-found")}
        </title>
      </Helmet>

      <div className="e404_container">
        <img src={ImgError404} alt="Error 404 imagen" className="e404_img" />

        <div className="e404_darkLayer" />
        <div className="e404_darkLayer" />

        <div className="e404">
          <h2>Oops! {t("error-404-not-found")}.</h2>

          <Button
            otherClassNameBtn="e404_linkToHome"
            type="link"
            link="/"
            title={t("error-404-to-home")}
          />
          {/* <Link to="/" target="_top" className="e404_linkToHome">
            {t("error-404-to-home")}
          </Link> */}
        </div>
      </div>
    </>
  );
};

export default Error404;
