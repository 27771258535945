/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./PreviewApplicants.css";
import { t } from "../../languages/settingsLanguages";
// ---------------------------------------------------------

const PreviewApplicants = ({docTitle}) => {
    return (
        <>
        {/* Class names starting with "pva" refer to the file: FormForCompany.css */}
        <Helmet>
          <title>
            {docTitle} | {t("name-link-preview-applicants")}
          </title>
        </Helmet>
        </>
    )
};

export default PreviewApplicants;
