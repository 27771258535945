/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { API_BASE, path_register_applicant } from "../../api/apis";
// ---------------------------------------------------------
// ---------------------------------------------------------

const servicesFormApplicant = async (
  data,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  loading(true);
  const API = API_BASE;
  console.log(`${API}${path_register_applicant}`);

  const formData = new FormData();

  formData.append("perfil", data.applicant_profileFile);
  formData.append("name", data.applicant_name);
  formData.append("lastname", data.applicant_lastname);
  formData.append("city", data.applicant_residence_city);
  formData.append("zip", data.applicant_postal_code);
  formData.append("tel", data.applicant_phone_number);
  formData.append("birthdate", data.applicant_birthdate);
  formData.append("gender", data.applicant_gender);
  formData.append("mail", data.applicant_mail);
  formData.append("password", data.applicant_password);
  formData.append("job", data.applicant_what_job);
  formData.append("apply", data.applicant_apply);
  formData.append("adjetives", JSON.stringify(data.applicant_adjetives));
  formData.append("timeexp", data.applicant_time_exp);
  formData.append("travel", data.applicant_travel);
  formData.append("selfdescription", data.applicant_selfdescription);
  formData.append("lngpref", data.applicant_lng_pref);
  formData.append("license", data.applicant_driving_license);
  formData.append("teamwork", data.applicant_teamwork);
  formData.append("pressure", data.applicant_stress_and_pressure);
  formData.append("working", data.applicant_I_enjoy_working);
  formData.append("skills", data.applicant_I_improve_my_skills);
  formData.append("adaptability", data.applicant_adaptability);
  formData.append("objectively", data.applicant_conflicts_objectively);
  formData.append("learning", data.applicant_I_enjoy_learning);
  formData.append("responsibility", data.applicant_responsibility);
  formData.append("extrahours", data.applicant_extra_hours);
  formData.append("satisfied", data.applicant_satisfied_in_job);
  formData.append("comment", data.applicant_short_comment);

  await fetch(`${API}${path_register_applicant}`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.response === "successful") {
        console.log(res.response);
        response("successful");
        loading(false);
      }
    })
    .catch((error) => {
      console.log(error);
      response("failed");
      loading(false);
    });
};

export default servicesFormApplicant;
