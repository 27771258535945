/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { defaultLanguage } from "../languages/settingsLanguages";
// ---------------------------------------------------------

const initialState = {
  defaultTest: false,
  language: defaultLanguage,
};

export default initialState;
