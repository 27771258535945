/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import { Helmet } from "react-helmet";
import "./ForApplicants.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import BannerWithChildren from "../../components/bannerWithChildren/BannerWithChildren";
import Paragraph from "../../components/paragraph/Paragraph";
import Button from "../../components/button/Button";
import ContainerWithColumns from "../../components/containerWithColumns/ContainerWithColumns";
// ---------------------------------------------------------

const ForApplicants = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "fap" refer to the file: ForApplicants.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-applicants")}
        </title>
      </Helmet>

      <Header />

      <div className="fap_main_container">
        <div className="fap_bg_img_container">
          <img
            src={require("../../assets/imgs/applicants-bg-city.jpg")}
            alt="Bg city"
            className="fap_bg_img"
          />

          <div className="fap_bg_img_darkLayer" />
        </div>

        <BannerWithChildren
          otherClassNameContainer="fap_banner_container"
          img={require("../../assets/imgs/banner-applicants.jpg")}
          alt="Work"
          opacityDarkLayer={40}
        >
          <div className="fap_child_container">
            <Paragraph
              withTitle
              title={t("applicants-banner-title")}
              otherClassNameParagraph="fap_child_p"
            >
              {t("applicants-banner-high-demand")}
            </Paragraph>

            <Button
              type="link"
              link="/register-applicant"
              title={t("name-link-to-register")}
            />
          </div>
        </BannerWithChildren>
      </div>

      <div className="fap_info_container">
        <Paragraph>{t("applicants-we-understand-you")}</Paragraph>

        <Paragraph withTitle title={t("applicants-is-that-how-it-works-title")}>
          <p>{t("applicants-is-that-how-it-works-p")}</p>

          <p>{t("applicants-is-that-how-it-works-p2")}</p>
        </Paragraph>

        <ContainerWithColumns>
          <div>
            <Paragraph withTitle title={t("applicants-most-notably-title")}>
              {t("applicants-most-notably-p")}
            </Paragraph>
          </div>

          <div>
            <Paragraph withTitle title={t("applicants-most-the-best-title")}>
              {t("applicants-most-the-best-p")}
            </Paragraph>
          </div>
        </ContainerWithColumns>

        <Paragraph>
          <p>{t("applicants-get-ready")}</p>

          <p>{t("applicants-we-want-to-help")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("applicants-how-do-we-do-this-title")}>
          <p>{t("applicants-how-do-we-do-this-p1")}</p>

          <p>{t("applicants-how-do-we-do-this-p2")}</p>

          <p>{t("applicants-how-do-we-do-this-p3")}</p>
        </Paragraph>
      </div>

      <div className="fap_second_container">
        <div className="fap_bg_img_container">
          <img
            src={require("../../assets/imgs/applicants-bg-city-2.jpg")}
            alt="Bg city"
            className="fap_bg_img"
          />

          <div className="fap_bg_img_darkLayer" />
        </div>

        <BannerWithChildren
          otherClassNameContainer="fap_second_banner_container"
          img={require("../../assets/imgs/banner-applicants-2.avif")}
          alt="Work"
          opacityDarkLayer={70}
        >
          <div className="fap_child_container">
            <Paragraph
              withTitle
              title={t("applicants-banner-2-title")}
              otherClassNameParagraph="fap_child_p"
            >
              <p>{t("applicants-banner-2-frightening")}</p>

              <p>{t("applicants-banner-2-goal-in-mind")}</p>

              <p>{t("applicants-banner-2-together-with-our-partner")}</p>
            </Paragraph>

            <div className="fap_imgPatner_container">
              <img
                src={require("../../assets/imgs/our-patner.png")}
                alt="Our Patner"
                className="fap_imgPatner_img"
              />
            </div>

            <Paragraph otherClassNameParagraph="fap_child_p">
              <p>{t("applicants-banner-2-we-are-engaged")}</p>

              <p>{t("applicants-banner-2-salary-atlas")}</p>
            </Paragraph>

            <Button
              type="link"
              link="https://web.arbeitsagentur.de/entgeltatlas/"
              target="_blank"
              otherClassNameBtn="fap_banner_btn"
              title={t("applicants-banner-2-payment-atlas")}
            />
          </div>
        </BannerWithChildren>
      </div>
    </>
  );
};

export default ForApplicants;
