/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
// ---------------------------------------------------------
import "./FormForCompany.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Button from "../../components/button/Button";
import TabBarSteps, { Step } from "../../components/tabBarSteps/TabBarSteps";
import servicesFormCompany from "./servicesFormCompany";
import Input, {
  ChildrenLabelDefault,
  TextArea,
} from "../../components/input/Input";
// ---------------------------------------------------------

const FormForCompany = ({ docTitle }) => {
  const [currentFormStep, setCurrentFormStep] = useState(1);
  const [arrCompleted, setArrCompleted] = useState([
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
  ]);
  const [form, setForm] = useState({
    // ------------- Step 1 -------------
    company_mail: "",
    company_password: "",
    company_repeat_password: "",
    // ------------- Step 2 -------------
    company_name: "",
    company_description: "",
    company_link_website: "",
    // ------------- Step 3 -------------
    company_contact_name: "",
    company_contact_mail: "",
    company_contact_phone: "",
    // ------------- Step 4 -------------
    company_position: "",
    company_conditions: "",
  });
  const [errorFree, setErrorFree] = useState({
    ...form,
    company_link_website: true,
  });
  const [isLoadingTabVisible, setIsLoadingTabVisible] = useState(false);
  const [isResultTabVisible, setIsResultTabVisible] = useState(false);

  // ---------------- Regular expresions ----------------
  let regIsMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // ---------------- Regular expresions ----------------

  const handleChangeStep = (step) => {
    setCurrentFormStep(step);
  };

  const handleChangeNextStep = (step) => {
    let next = step + 1;

    handleChangeStep(next);
  };

  const handleChangeBackStep = (step) => {
    let back = step - 1;

    handleChangeStep(back);
  };

  const handleChangeInput = (target, isOK) => {
    setForm({
      ...form,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  const handleResponse = (res) => {
    return setIsResultTabVisible(res);
  };

  const handleLoadingFinish = (loading) => {
    return setIsLoadingTabVisible(loading);
  };

  // --------------------- Validating all steps ---------------------
  const handleChangeCompleted = (step, state = false) => {
    let indexStep = step - 1;
    let copyArrCompleted = arrCompleted;

    copyArrCompleted[indexStep] = { completed: state };

    return setArrCompleted(copyArrCompleted);
  };

  // ------------ Validation step 1 ------------
  if (currentFormStep === 1) {
    if (!arrCompleted[0].completed) {
      // company_mail
      // company_password
      // company_repeat_password
      if (
        errorFree.company_mail &&
        errorFree.company_password &&
        errorFree.company_repeat_password
      )
        handleChangeCompleted(1, true);
    } else if (arrCompleted[0].completed) {
      if (
        !errorFree.company_mail ||
        !errorFree.company_password ||
        !errorFree.company_repeat_password
      )
        handleChangeCompleted(1, false);
    }
    // ------------ /Validation step 1 ------------

    // ------------ Validation step 2 ------------
  } else if (currentFormStep === 2) {
    if (!arrCompleted[1].completed) {
      // company_name
      // company_description
      // company_link_website
      if (errorFree.company_name && errorFree.company_description) {
        handleChangeCompleted(2, true);
      }
    } else if (arrCompleted[1].completed) {
      if (!errorFree.company_name || !errorFree.company_description) {
        handleChangeCompleted(2, false);
      }
    }
    // ------------ /Validation step 2 ------------

    // ------------ Validation step 3 ------------
  } else if (currentFormStep === 3) {
    if (!arrCompleted[2].completed) {
      // company_contact_name
      // company_contact_mail
      // company_contact_phone
      if (
        errorFree.company_contact_name &&
        errorFree.company_contact_mail &&
        errorFree.company_contact_phone
      ) {
        handleChangeCompleted(3, true);
      }
    } else if (arrCompleted[2].completed) {
      if (
        !errorFree.company_contact_name ||
        !errorFree.company_contact_mail ||
        !errorFree.company_contact_phone
      ) {
        handleChangeCompleted(3, false);
      }
    }
    // ------------ /Validation step 3 ------------

    // ------------ Validation step 4 ------------
  } else if (currentFormStep === 4) {
    if (!arrCompleted[3].completed) {
      // company_position
      // company_conditions
      if (errorFree.company_position && errorFree.company_conditions) {
        handleChangeCompleted(4, true);
      }
    } else if (arrCompleted[3].completed) {
      if (!errorFree.company_position || !errorFree.company_conditions) {
        handleChangeCompleted(4, false);
      }
    }
    // ------------ /Validation step 4 ------------

    // ------------ Validation step 5 ------------
  }
  // --------------------- /Validating all steps ---------------------

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isResultTabVisible !== "successful") {
        // The standard message that will be displayed may be different depending on the browser.
        let mensaje = "Are you sure you want to go out?";

        // Set the return message for the event.
        e.returnValue = mensaje; // Standard for some browsers.

        return mensaje; // Some browsers may require the message to be returned.
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
  }, [isResultTabVisible]);

  return (
    <>
      {/* Class names starting with "ffc" refer to the file: FormForCompany.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-form-company")}
        </title>
      </Helmet>

      <Header />

      <div className="ffc_main_container">
        {/* ------------------ Tab Loading ------------------ */}
        <div
          className={`ffa_tab_loading_container ${
            isLoadingTabVisible && "ffa_tab_loading_active"
          }`}
        >
          {isLoadingTabVisible && (
            <div className="ffa_loading_illustration_container">
              <img
                src={require("../../assets/icons/icon-sending.png")}
                alt="Sending"
                className="ffa_loading_img"
              />
              <span className="ffa_loading_active_loader" />
            </div>
          )}
        </div>
        {/* ------------------ /Tab Loading ------------------ */}

        {/* ------------------ Tab Response ------------------ */}
        <div
          className={`ffa_tab_responseMsm_container ${
            isResultTabVisible !== false && "ffa_tab_responseMsm_active"
          }`}
        >
          <h4>
            {isResultTabVisible === "successful"
              ? t("form-register-successful")
              : t("form-register-failed")}
          </h4>

          <div className="ffa_tab_responseMsm_img_cont">
            {isResultTabVisible === "successful" ? (
              <img
                src={require("../../assets/imgs/successful.png")}
                alt="Register successful"
                className="ffa_tab_responseMsm_img"
              />
            ) : (
              <img
                src={require("../../assets/imgs/failed.png")}
                alt="Register failed"
                className="ffa_tab_responseMsm_img"
              />
            )}
          </div>

          {isResultTabVisible !== "successful" && (
            <p className="ffa_tab_responseMsm_oops">
              {t("form-register-failed-oops")}
            </p>
          )}

          {isResultTabVisible === "successful" ? (
            <Button
              type="link"
              link="/register-applicant"
              title={t("form-btn-register-close")}
            />
          ) : (
            <Button
              title={t("form-btn-register-retry")}
              onclick={() => handleResponse(false)}
            />
          )}
        </div>
        {/* ------------------ /Tab Response ------------------ */}

        <TabBarSteps
          currentFormStep={currentFormStep}
          arr={arrCompleted}
          onclick={(step) => handleChangeStep(step)}
        />

        <form id="register-company" className="ffc_form_container">
          {currentFormStep === 1 && (
            <Step
              numStep={1}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-company-step-1-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- Email -------------------- */}
              <Input
                id="company_mail"
                nameInput="company_mail"
                // placeholder={t("form-company-step-1-register-mail")}
                placeholder="yourmail@mail.com"
                defaultValue={form.company_mail}
                type="email"
                required
                handleBlurContainsError={(e) => {
                  return (
                    e.target.value.length < 6 ||
                    !regIsMail.test(form.company_mail)
                  );
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-register-mail")}
                  />
                }
                errorMessage={
                  form.company_mail.length === 0
                    ? t("form-required")
                    : !regIsMail.test(form.company_mail)
                    ? t("form-company-step-1-please-valid-mail")
                    : ""
                }
              />
              {/* -------------------- /Email -------------------- */}

              {/* -------------------- Password -------------------- */}
              <Input
                id="company_password"
                nameInput="company_password"
                // placeholder={t("form-company-step-1-password")}
                placeholder="******"
                defaultValue={form.company_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value.length < 6;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-password")}
                  />
                }
                errorMessage={
                  form.company_password.length === 0
                    ? t("form-required")
                    : form.company_password.length < 6
                    ? t("form-company-step-1-please-valid-password")
                    : ""
                }
              />
              {/* -------------------- /Password -------------------- */}

              {/* -------------------- Repeat password -------------------- */}
              <Input
                id="company_repeat_password"
                nameInput="company_repeat_password"
                // placeholder={t("form-company-step-1-repeat-password")}
                placeholder="******"
                defaultValue={form.company_repeat_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value !== form.company_password;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-1-repeat-password")}
                  />
                }
                errorMessage={
                  form.company_repeat_password.length === 0
                    ? t("form-required")
                    : form.company_repeat_password !== form.company_password
                    ? t("form-company-step-1-please-valid-repeat-password")
                    : ""
                }
              />
              {/* -------------------- /Repeat password -------------------- */}
            </Step>
          )}

          {currentFormStep === 2 && (
            <Step
              numStep={2}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-company-step-2-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* ------------------ Company Name ------------------ */}
              <Input
                id="company_name"
                nameInput="company_name"
                // placeholder={t("form-company-step-2-compname")}
                placeholder="Company Name"
                defaultValue={form.company_name}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-2-compname")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Company Name ----------------------- */}

              {/* ----------------- Company Description ----------------- */}
              <TextArea
                id="company_description"
                label={t("form-company-step-2-description")}
                placeholder={t("form-company-step-2-description")}
                responseTextArea={(target, isOK) => {
                  handleChangeInput(target, isOK);
                }}
                defaultValue={form.company_description}
                required
                error={errorFree.company_description === false}
                errorMessage={t("form-required")}
              />
              {/* ----------------- /Company Description ----------------- */}

              {/* -------------- Company Link Website -------------- */}
              <Input
                id="company_link_website"
                nameInput="company_link_website"
                type="url"
                placeholder="https://yourwebsite.com"
                defaultValue={form.company_link_website}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    txt={t("form-company-step-2-link-website")}
                  />
                }
              />
              {/* -------------- /Company Link Website ------------------- */}
            </Step>
          )}

          {currentFormStep === 3 && (
            <Step
              numStep={3}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-company-step-3-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* ------------------ Contact Name ------------------ */}
              <Input
                id="company_contact_name"
                nameInput="company_contact_name"
                placeholder={t("form-company-step-3-contact-name")}
                defaultValue={form.company_contact_name}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-3-contact-name")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Contact Name ----------------------- */}

              {/* -------------------- Contact Email -------------------- */}
              <Input
                id="company_contact_mail"
                nameInput="company_contact_mail"
                // placeholder={t("form-company-step-1-register-mail")}
                placeholder="yourmail@mail.com"
                defaultValue={form.company_contact_mail}
                type="email"
                required
                handleBlurContainsError={(e) => {
                  return (
                    e.target.value.length < 6 ||
                    !regIsMail.test(form.company_contact_mail)
                  );
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-3-contact-mail")}
                  />
                }
                errorMessage={
                  form.company_contact_mail.length === 0
                    ? t("form-required")
                    : !regIsMail.test(form.company_contact_mail)
                    ? t("form-company-step-1-please-valid-mail")
                    : ""
                }
              />
              {/* -------------------- /Contact Email -------------------- */}

              {/* ---------------- Contact Phone number ---------------- */}
              <Input
                id="company_contact_phone"
                nameInput="company_contact_phone"
                // placeholder={t("form-applicant-step-1-phone-number")}
                placeholder="04900000000"
                defaultValue={form.company_contact_phone}
                type="tel"
                required
                handleBlurContainsError={(e) => {
                  let validNumberPhone =
                    e.target.value.length < 11 || e.target.value.length > 13;

                  return validNumberPhone;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-3-contact-phone")}
                  />
                }
                errorMessage={
                  form.company_contact_phone.length === 0
                    ? t("form-required")
                    : form.company_contact_phone.length < 11 ||
                      form.company_contact_phone.length > 13
                    ? t("form-applicant-step-1-please-valid-number")
                    : ""
                }
              />
              {/* ---------------- /Contact Phone number ---------------- */}
            </Step>
          )}

          {currentFormStep === 4 && (
            <Step
              numStep={4}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-company-step-4-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
              lastStep
              onFinish={() =>
                servicesFormCompany(
                  form,
                  (res) => handleResponse(res),
                  (loading) => handleLoadingFinish(loading)
                )
              }
              custonNameBtnNext={t("form-btn-register")}
            >
              {/* ---------------- Company Position ---------------- */}
              <Input
                id="company_position"
                nameInput="company_position"
                placeholder={t("form-company-step-4-company-position")}
                defaultValue={form.company_position}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-company-step-3-contact-name")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* ---------------- /Company Position --------------------- */}

              {/* ----------------- Company Conditions ----------------- */}
              <TextArea
                id="company_conditions"
                label={t("form-company-step-4-company-conditions")}
                placeholder={t("form-company-step-4-company-conditions")}
                responseTextArea={(target, isOK) => {
                  handleChangeInput(target, isOK);
                }}
                defaultValue={form.company_conditions}
                required
                error={errorFree.company_conditions === false}
                errorMessage={t("form-required")}
              />
              {/* ----------------- /Company Conditions ----------------- */}
            </Step>
          )}
        </form>
      </div>
    </>
  );
};

export default FormForCompany;
