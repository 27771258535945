/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./FormForApplicant.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import TabBarSteps, { Step } from "../../components/tabBarSteps/TabBarSteps";
import Input, {
  ChildrenLabelDefault,
  Options,
  Selector,
  TextArea,
} from "../../components/input/Input";
import {
  option_choose,
  experienceOptions,
  timeExpOptions,
  lngPrefOptions,
  okOptions,
  travelOptions,
  adjetivesOptions,
} from "../../utils/options";
import servicesFormApplicant from "./servicesFormApplicant";
import Button from "../../components/button/Button";
// ---------------------------------------------------------

const FormForApplicant = ({ docTitle }) => {
  const [currentFormStep, setCurrentFormStep] = useState(1);
  const [arrCompleted, setArrCompleted] = useState([
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
  ]);
  const [form, setForm] = useState({
    // ------------- Step 1 -------------
    applicant_profileFile: null,
    applicant_name: "",
    applicant_lastname: "",
    applicant_residence_city: "",
    applicant_postal_code: "",
    applicant_phone_number: "",
    applicant_birthdate: "",
    applicant_gender: "",
    applicant_mail: "",
    applicant_password: "",
    applicant_repeat_password: "",
    // ------------- Step 2 -------------
    applicant_what_job: "",
    applicant_apply: "",
    applicant_adjetives: {
      one: "",
      two: "",
      three: "",
    },
    // ------------- Step 3 -------------
    applicant_time_exp: "",
    applicant_travel: "",
    applicant_selfdescription: "",
    applicant_lng_pref: "",
    applicant_driving_license: "",
    // ------------- Step 4 -------------
    applicant_teamwork: "",
    applicant_stress_and_pressure: "",
    applicant_I_enjoy_working: "",
    applicant_I_improve_my_skills: "",
    applicant_adaptability: "",
    // ------------- Step 5 -------------
    applicant_conflicts_objectively: "",
    applicant_I_enjoy_learning: "",
    applicant_responsibility: "",
    applicant_extra_hours: "",
    applicant_satisfied_in_job: "",
    applicant_short_comment: "",
  });
  const [errorFree, setErrorFree] = useState({
    ...form,
    applicant_adjetives: true,
    applicant_short_comment: true,
  });
  const [validAge, setValidAge] = useState("");
  const [isLoadingTabVisible, setIsLoadingTabVisible] = useState(false);
  const [isResultTabVisible, setIsResultTabVisible] = useState(false);

  // ---------------- Regular expresions ----------------
  let regIsMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // ---------------- Regular expresions ----------------

  const handleChangeStep = (step) => {
    setCurrentFormStep(step);
  };

  const handleChangeNextStep = (step) => {
    let next = step + 1;

    handleChangeStep(next);
  };

  const handleChangeBackStep = (step) => {
    let back = step - 1;

    handleChangeStep(back);
  };

  const handleChangeInput = (target, isOK) => {
    setForm({
      ...form,
      [target.name]: target.value,
    });

    setErrorFree({
      ...errorFree,
      [target.name]: isOK,
    });
  };

  const handleChangeAdjetives = (value) => {
    const setFormAdjectives = (name, value) => {
      setForm({
        ...form,
        applicant_adjetives: {
          ...form.applicant_adjetives,
          [name]: value,
        },
      });
    };

    if (form.applicant_adjetives.one === value) {
      setFormAdjectives("one", "");
    } else if (form.applicant_adjetives.two === value) {
      setFormAdjectives("two", "");
    } else if (form.applicant_adjetives.three === value) {
      setFormAdjectives("three", "");
    } else if (form.applicant_adjetives.one === "") {
      setFormAdjectives("one", value);
    } else if (form.applicant_adjetives.two === "") {
      setFormAdjectives("two", value);
    } else if (form.applicant_adjetives.three === "") {
      setFormAdjectives("three", value);
    }
  };

  const handleResponse = (res) => {
    return setIsResultTabVisible(res);
  };

  const handleLoadingFinish = (loading) => {
    return setIsLoadingTabVisible(loading);
  };

  const verifyAge = (e) => {
    let _birthdate = e.target.value;
    let today = new Date();
    let birthdate = new Date(_birthdate);
    let age = today.getFullYear() - birthdate.getFullYear();
    let m = birthdate.getMonth() - today.getMonth();
    let day =
      Number(_birthdate.slice(_birthdate.length - 2, _birthdate.length)) -
      Number(`${today}`.slice(7, 10));

    let greater_than =
      age === 18 && m < 0
        ? false
        : age === 18 && m === 0 && day < 0
        ? false
        : age < 17
        ? false
        : true;

    setValidAge(greater_than);

    return !greater_than;
  };

  // --------------------- Validating all steps ---------------------
  const handleChangeCompleted = (step, state = false) => {
    let indexStep = step - 1;
    let copyArrCompleted = arrCompleted;

    copyArrCompleted[indexStep] = { completed: state };

    return setArrCompleted(copyArrCompleted);
  };

  // ------------ Validation step 1 ------------
  if (currentFormStep === 1) {
    if (!arrCompleted[0].completed) {
      // applicant_name
      // applicant_lastname
      // applicant_residence_city
      // applicant_postal_code
      // applicant_phone_number
      // applicant_birthdate
      // applicant_gender
      // applicant_mail
      // applicant_password
      // applicant_repeat_password
      if (
        errorFree.applicant_name &&
        errorFree.applicant_lastname &&
        errorFree.applicant_residence_city &&
        errorFree.applicant_postal_code &&
        errorFree.applicant_phone_number &&
        errorFree.applicant_birthdate &&
        errorFree.applicant_gender &&
        errorFree.applicant_mail &&
        errorFree.applicant_password &&
        errorFree.applicant_repeat_password
      )
        handleChangeCompleted(1, true);
    } else if (arrCompleted[0].completed) {
      if (
        !errorFree.applicant_name ||
        !errorFree.applicant_lastname ||
        !errorFree.applicant_residence_city ||
        !errorFree.applicant_postal_code ||
        !errorFree.applicant_phone_number ||
        !errorFree.applicant_birthdate ||
        !errorFree.applicant_gender ||
        !errorFree.applicant_mail ||
        !errorFree.applicant_password ||
        !errorFree.applicant_repeat_password
      )
        handleChangeCompleted(1, false);
    }
    // ------------ /Validation step 1 ------------

    // ------------ Validation step 2 ------------
  } else if (currentFormStep === 2) {
    if (!arrCompleted[1].completed) {
      // applicant_what_job
      // applicant_apply
      if (errorFree.applicant_what_job && errorFree.applicant_apply) {
        handleChangeCompleted(2, true);
      }
    } else if (arrCompleted[1].completed) {
      if (!errorFree.applicant_what_job || !errorFree.applicant_apply) {
        handleChangeCompleted(2, false);
      }
    }
    // ------------ /Validation step 2 ------------

    // ------------ Validation step 3 ------------
  } else if (currentFormStep === 3) {
    if (!arrCompleted[2].completed) {
      // applicant_time_exp
      // applicant_travel
      // applicant_selfdescription
      // applicant_lng_pref
      // applicant_driving_license
      if (
        errorFree.applicant_time_exp &&
        errorFree.applicant_travel &&
        errorFree.applicant_selfdescription &&
        errorFree.applicant_lng_pref &&
        errorFree.applicant_driving_license
      ) {
        handleChangeCompleted(3, true);
      }
    } else if (arrCompleted[2].completed) {
      if (
        !errorFree.applicant_time_exp ||
        !errorFree.applicant_travel ||
        !errorFree.applicant_selfdescription ||
        !errorFree.applicant_lng_pref ||
        !errorFree.applicant_driving_license
      ) {
        handleChangeCompleted(3, false);
      }
    }
    // ------------ /Validation step 3 ------------

    // ------------ Validation step 4 ------------
  } else if (currentFormStep === 4) {
    if (!arrCompleted[3].completed) {
      // applicant_teamwork
      // applicant_stress_and_pressure
      // applicant_I_enjoy_working
      // applicant_I_improve_my_skills
      // applicant_adaptability
      if (
        errorFree.applicant_teamwork &&
        errorFree.applicant_stress_and_pressure &&
        errorFree.applicant_I_enjoy_working &&
        errorFree.applicant_I_improve_my_skills &&
        errorFree.applicant_adaptability
      ) {
        handleChangeCompleted(4, true);
      }
    } else if (arrCompleted[3].completed) {
      if (
        !errorFree.applicant_teamwork ||
        !errorFree.applicant_stress_and_pressure ||
        !errorFree.applicant_I_enjoy_working ||
        !errorFree.applicant_I_improve_my_skills ||
        !errorFree.applicant_adaptability
      ) {
        handleChangeCompleted(4, false);
      }
    }
    // ------------ /Validation step 4 ------------

    // ------------ Validation step 5 ------------
  } else if (currentFormStep === 5) {
    if (!arrCompleted[4].completed) {
      // applicant_conflicts_objectively
      // applicant_I_enjoy_learning
      // applicant_responsibility
      // applicant_extra_hours
      // applicant_satisfied_in_job
      // applicant_short_comment
      if (
        errorFree.applicant_conflicts_objectively &&
        errorFree.applicant_I_enjoy_learning &&
        errorFree.applicant_responsibility &&
        errorFree.applicant_extra_hours &&
        errorFree.applicant_satisfied_in_job &&
        errorFree.applicant_short_comment
      ) {
        handleChangeCompleted(5, true);
      }
    } else if (arrCompleted[4].completed) {
      if (
        !errorFree.applicant_conflicts_objectively ||
        !errorFree.applicant_I_enjoy_learning ||
        !errorFree.applicant_responsibility ||
        !errorFree.applicant_extra_hours ||
        !errorFree.applicant_satisfied_in_job ||
        !errorFree.applicant_short_comment
      ) {
        handleChangeCompleted(5, false);
      }
    }
    // ------------ /Validation step 5 ------------
  }
  // --------------------- /Validating all steps ---------------------

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isResultTabVisible !== "successful") {
        // The standard message that will be displayed may be different depending on the browser.
        let mensaje = "Are you sure you want to go out?";

        // Set the return message for the event.
        e.returnValue = mensaje; // Standard for some browsers.

        return mensaje; // Some browsers may require the message to be returned.
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
  }, [isResultTabVisible]);

  return (
    <>
      {/* Class names starting with "ffa" refer to the file: FormForApplicant.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-form-applicant")}
        </title>
      </Helmet>

      <Header />

      <div className="ffa_main_container">
        {/* ------------------ Tab Loading ------------------ */}
        <div
          className={`ffa_tab_loading_container ${
            isLoadingTabVisible && "ffa_tab_loading_active"
          }`}
        >
          {isLoadingTabVisible && (
            <div className="ffa_loading_illustration_container">
              <img
                src={require("../../assets/icons/icon-sending.png")}
                alt="Sending"
                className="ffa_loading_img"
              />
              <span className="ffa_loading_active_loader" />
            </div>
          )}
        </div>
        {/* ------------------ /Tab Loading ------------------ */}

        {/* ------------------ Tab Response ------------------ */}
        <div
          className={`ffa_tab_responseMsm_container ${
            isResultTabVisible !== false && "ffa_tab_responseMsm_active"
          }`}
        >
          <h4>
            {isResultTabVisible === "successful"
              ? t("form-register-successful")
              : t("form-register-failed")}
          </h4>

          <div className="ffa_tab_responseMsm_img_cont">
            {isResultTabVisible === "successful" ? (
              <img
                src={require("../../assets/imgs/successful.png")}
                alt="Register successful"
                className="ffa_tab_responseMsm_img"
              />
            ) : (
              <img
                src={require("../../assets/imgs/failed.png")}
                alt="Register failed"
                className="ffa_tab_responseMsm_img"
              />
            )}
          </div>

          {isResultTabVisible !== "successful" && (
            <p className="ffa_tab_responseMsm_oops">
              {t("form-register-failed-oops")}
            </p>
          )}

          {isResultTabVisible === "successful" ? (
            <Button
              type="link"
              link="/register-applicant"
              title={t("form-btn-register-close")}
            />
          ) : (
            <Button
              title={t("form-btn-register-retry")}
              onclick={() => handleResponse(false)}
            />
          )}
        </div>
        {/* ------------------ /Tab Response ------------------ */}

        <TabBarSteps
          currentFormStep={currentFormStep}
          arr={arrCompleted}
          onclick={(step) => handleChangeStep(step)}
        />

        <form id="register-applicant" className="ffa_form_container">
          {currentFormStep === 1 && (
            <Step
              numStep={1}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-1-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- ProfileFile -------------------- */}
              <Input
                type="file"
                id="applicant_profileFile"
                nameInput="applicant_profileFile"
                defaultValue={form.applicant_profileFile}
                responseInput={(target, isOK) =>
                  setForm({
                    ...form,
                    applicant_profileFile: target.files[0],
                  })
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    txt={t("form-applicant-step-1-profile-file")}
                  />
                }
              />
              {/* -------------------- /ProfileFile ------------------------- */}

              {/* -------------------- Name -------------------- */}
              <Input
                id="applicant_name"
                nameInput="applicant_name"
                // placeholder={t("form-applicant-step-1-name")}
                placeholder="Jon"
                defaultValue={form.applicant_name}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-name")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Name ------------------------- */}

              {/* -------------------- Last Name -------------------- */}
              <Input
                id="applicant_lastname"
                nameInput="applicant_lastname"
                // placeholder={t("form-applicant-step-1-lastname")}
                placeholder="Gu"
                defaultValue={form.applicant_lastname}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-lastname")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Last Name -------------------- */}

              {/* -------------------- Residence city -------------------- */}
              <Input
                id="applicant_residence_city"
                nameInput="applicant_residence_city"
                // placeholder={t("form-applicant-step-1-residence-city")}
                placeholder="Bremen"
                defaultValue={form.applicant_residence_city}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-residence-city")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Residence city -------------------- */}

              {/* -------------------- Postal code -------------------- */}
              <Input
                id="applicant_postal_code"
                nameInput="applicant_postal_code"
                type="number"
                // placeholder={t("form-applicant-step-1-postal-code")}
                placeholder="000000"
                defaultValue={form.applicant_postal_code}
                minLength={11}
                maxLength={13}
                required
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-postal-code")}
                  />
                }
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Postal code -------------------- */}

              {/* -------------------- Phone number -------------------- */}
              <Input
                id="applicant_phone_number"
                nameInput="applicant_phone_number"
                // placeholder={t("form-applicant-step-1-phone-number")}
                placeholder="04900000000"
                defaultValue={form.applicant_phone_number}
                type="tel"
                required
                handleBlurContainsError={(e) => {
                  let validNumberPhone =
                    e.target.value.length <= 11 || e.target.value.length >= 13;

                  return validNumberPhone;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-phone-number")}
                  />
                }
                errorMessage={
                  form.applicant_phone_number.length === 0
                    ? t("form-required")
                    : form.applicant_phone_number.length < 11 ||
                      form.applicant_phone_number.length > 13
                    ? t("form-applicant-step-1-please-valid-number")
                    : ""
                }
              />
              {/* -------------------- /Phone number -------------------- */}

              {/* -------------------- Birthdate -------------------- */}
              <Input
                id="applicant_birthdate"
                nameInput="applicant_birthdate"
                placeholder={t("form-applicant-step-1-birthdate")}
                defaultValue={form.applicant_birthdate}
                type="date"
                required
                handleBlurContainsError={(e) => {
                  return verifyAge(e);
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-birthdate")}
                  />
                }
                errorMessage={
                  !validAge
                    ? t("form-applicant-step-1-please-valid-age")
                    : t("form-required")
                }
              />
              {/* -------------------- /Birthdate -------------------- */}

              {/* -------------------- Gender -------------------- */}
              <Options
                label={t("form-applicant-step-1-gender")}
                arr={[
                  {
                    name: t("form-gender-male"),
                    value: "male",
                  },
                  {
                    name: t("form-gender-female"),
                    value: "female",
                  },
                  {
                    name: t("form-gender-other"),
                    value: "other",
                  },
                ]}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_gender",
                      value: value === form.applicant_gender ? "" : value,
                    },
                    value !== form.applicant_gender
                  );
                }}
                select={form.applicant_gender}
                required
                error={errorFree.applicant_gender === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Gender -------------------- */}

              {/* -------------------- Email -------------------- */}
              <Input
                id="applicant_mail"
                nameInput="applicant_mail"
                // placeholder={t("form-applicant-step-1-mail")}
                placeholder="yourmail@mail.com"
                defaultValue={form.applicant_mail}
                type="email"
                required
                handleBlurContainsError={(e) => {
                  return (
                    e.target.value.length < 6 ||
                    !regIsMail.test(form.applicant_mail)
                  );
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-mail")}
                  />
                }
                errorMessage={
                  form.applicant_mail.length === 0
                    ? t("form-required")
                    : !regIsMail.test(form.applicant_mail)
                    ? t("form-applicant-step-1-please-valid-mail")
                    : ""
                }
              />
              {/* -------------------- /Email -------------------- */}

              {/* -------------------- Password -------------------- */}
              <Input
                id="applicant_password"
                nameInput="applicant_password"
                // placeholder={t("form-applicant-step-1-password")}
                placeholder="******"
                defaultValue={form.applicant_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value.length < 6;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-password")}
                  />
                }
                errorMessage={
                  form.applicant_password.length === 0
                    ? t("form-required")
                    : form.applicant_password.length < 6
                    ? t("form-applicant-step-1-please-valid-password")
                    : ""
                }
              />
              {/* -------------------- /Password -------------------- */}

              {/* -------------------- Repeat password -------------------- */}
              <Input
                id="applicant_repeat_password"
                nameInput="applicant_repeat_password"
                // placeholder={t("form-applicant-step-1-repeat-password")}
                placeholder="******"
                defaultValue={form.applicant_repeat_password}
                type="password"
                required
                handleBlurContainsError={(e) => {
                  return e.target.value !== form.applicant_password;
                }}
                responseInput={(target, isOK) =>
                  handleChangeInput(target, isOK)
                }
                label
                childrenLabel={
                  <ChildrenLabelDefault
                    required
                    txt={t("form-applicant-step-1-repeat-password")}
                  />
                }
                errorMessage={
                  form.applicant_repeat_password.length === 0
                    ? t("form-required")
                    : form.applicant_repeat_password !== form.applicant_password
                    ? t("form-applicant-step-1-please-valid-repeat-password")
                    : ""
                }
              />
              {/* -------------------- /Repeat password -------------------- */}
            </Step>
          )}

          {currentFormStep === 2 && (
            <Step
              numStep={2}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-2-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- What job -------------------- */}
              <Selector
                id="applicant_what_job"
                label={t("form-applicant-step-2-what-job")}
                arr={experienceOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_what_job",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_what_job}
                required
                error={errorFree.applicant_what_job === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /What job -------------------- */}

              {/* -------------------- Apply -------------------- */}
              <Options
                label={t("form-applicant-step-2-apply")}
                arr={[
                  {
                    name: t("form-applicant-step-2-lateral"),
                    value: "lateral",
                  },
                  {
                    name: t("form-applicant-step-2-journeyman"),
                    value: "journeyman",
                  },
                  {
                    name: t("form-applicant-step-2-master"),
                    value: "master",
                  },
                ]}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_apply",
                      value: value === form.applicant_apply ? "" : value,
                    },
                    value !== form.applicant_apply
                  );
                }}
                select={form.applicant_apply}
                required
                error={errorFree.applicant_apply === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Apply -------------------- */}

              {/* -------------------- Adjetives -------------------- */}
              <Options
                otherClassNameOption="ffa_options_adjetives"
                label={t("form-applicant-step-2-adjective")}
                arr={adjetivesOptions}
                onclick={(value) => handleChangeAdjetives(value)}
                select={form.applicant_adjetives}
                arrSelects={[
                  { value: form.applicant_adjetives.one },
                  { value: form.applicant_adjetives.two },
                  { value: form.applicant_adjetives.three },
                ]}
              />
              {/* -------------------- /Adjetives -------------------- */}
            </Step>
          )}

          {currentFormStep === 3 && (
            <Step
              numStep={3}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-3-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- Exp -------------------- */}
              <Selector
                label={t("form-applicant-step-3-time-exp")}
                arr={timeExpOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_time_exp",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_time_exp}
                required
                error={errorFree.applicant_time_exp === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Exp -------------------- */}

              {/* -------------------- Travel -------------------- */}
              <Selector
                label={t("form-applicant-step-3-travel")}
                arr={travelOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_travel",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_travel}
                required
                error={errorFree.applicant_travel === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Travel -------------------- */}

              {/* -------------------- Qualifications -------------------- */}
              <TextArea
                id="applicant_selfdescription"
                label={t("form-applicant-step-3-selfdescription")}
                placeholder={t("form-applicant-step-3-selfdescription")}
                responseTextArea={(target, isOK) => {
                  handleChangeInput(target, isOK);
                }}
                defaultValue={form.applicant_selfdescription}
                required
                error={errorFree.applicant_selfdescription === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Qualifications -------------------- */}

              {/* ----------------- Language preference ----------------- */}

              <Selector
                label={t("form-applicant-step-3-lng-pref")}
                arr={lngPrefOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_lng_pref",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_lng_pref}
                required
                error={errorFree.applicant_lng_pref === false}
                errorMessage={t("form-required")}
              />
              {/* ----------------- /Language preference ----------------- */}

              {/* -------------------- License -------------------- */}
              <Options
                label={t("form-applicant-step-3-license")}
                arr={[
                  {
                    name: t("form-applicant-step-3-license-option-1"),
                    value: "no",
                  },
                  {
                    name: t("form-applicant-step-3-license-option-2"),
                    value: "class-b",
                  },
                  {
                    name: t("form-applicant-step-3-license-option-3"),
                    value: "class-b-c",
                  },
                ]}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_driving_license",
                      value:
                        value === form.applicant_driving_license ? "" : value,
                    },
                    value !== form.applicant_driving_license
                  );
                }}
                select={form.applicant_driving_license}
                required
                error={errorFree.applicant_driving_license === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /License -------------------- */}
            </Step>
          )}

          {currentFormStep === 4 && (
            <Step
              numStep={4}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-4-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
            >
              {/* -------------------- Teamwork -------------------- */}
              <Selector
                id="applicant_teamwork"
                label={t("form-applicant-step-4-teamwork")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_teamwork",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_teamwork}
                required
                error={errorFree.applicant_teamwork === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Teamwork -------------------- */}

              {/* ------------------ Stress and pressure ------------------ */}
              <Selector
                id="applicant_stress_and_pressure"
                label={t("form-applicant-step-4-stress-and-pressure")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_stress_and_pressure",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_stress_and_pressure}
                required
                error={errorFree.applicant_stress_and_pressure === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Stress and pressure ------------------ */}

              {/* ------------------ I enjoy working ------------------ */}
              <Selector
                id="applicant_I_enjoy_working"
                label={t("form-applicant-step-4-I-enjoy-working")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_I_enjoy_working",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_I_enjoy_working}
                required
                error={errorFree.applicant_I_enjoy_working === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I enjoy working ------------------ */}

              {/* ------------------ I improve my skills ------------------ */}
              <Selector
                id="applicant_I_improve_my_skills"
                label={t("form-applicant-step-4-I-improve-my-skills")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_I_improve_my_skills",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_I_improve_my_skills}
                required
                error={errorFree.applicant_I_improve_my_skills === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I improve my skills ------------------ */}

              {/* ------------------ Adaptability ------------------ */}
              <Selector
                id="applicant_adaptability"
                label={t("form-applicant-step-4-adaptability")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_adaptability",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_adaptability}
                required
                error={errorFree.applicant_adaptability === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Adaptability ------------------ */}
            </Step>
          )}

          {currentFormStep === 5 && (
            <Step
              numStep={5}
              currentFormStep={currentFormStep}
              arr={arrCompleted}
              title={t("form-applicant-step-5-title")}
              onClickBack={(currentStep) => handleChangeBackStep(currentStep)}
              onClickNext={(currentStep) => handleChangeNextStep(currentStep)}
              lastStep
              onFinish={() =>
                servicesFormApplicant(
                  form,
                  (res) => handleResponse(res),
                  (loading) => handleLoadingFinish(loading)
                )
              }
              custonNameBtnNext={t("form-btn-register")}
            >
              {/* ----------------- Conflicts Objectively ----------------- */}
              <Selector
                id="applicant_conflicts_objectively"
                label={t("form-applicant-step-5-conflicts-objectively")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_conflicts_objectively",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_conflicts_objectively}
                required
                error={errorFree.applicant_conflicts_objectively === false}
                errorMessage={t("form-required")}
              />
              {/* ----------------- /Conflicts Objectively ----------------- */}

              {/* ------------------ I enjoy learning ------------------ */}
              <Selector
                id="applicant_I_enjoy_learning"
                label={t("form-applicant-step-5-I-enjoy-learning")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_I_enjoy_learning",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_I_enjoy_learning}
                required
                error={errorFree.applicant_I_enjoy_learning === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /I enjoy learning ------------------ */}

              {/* ------------------ Responsibility ------------------ */}
              <Selector
                id="applicant_responsibility"
                label={t("form-applicant-step-5-responsibility")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_responsibility",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_responsibility}
                required
                error={errorFree.applicant_responsibility === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Responsibility ------------------ */}

              {/* ------------------ Extra hours ------------------ */}
              <Selector
                id="applicant_extra_hours"
                label={t("form-applicant-step-5-extra-hours")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_extra_hours",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_extra_hours}
                required
                error={errorFree.applicant_extra_hours === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Extra hours ------------------ */}

              {/* ------------------ Satisfied in job ------------------ */}
              <Selector
                id="applicant_satisfied_in_job"
                label={t("form-applicant-step-5-satisfied-in-job")}
                arr={okOptions}
                onclick={(value) => {
                  handleChangeInput(
                    {
                      name: "applicant_satisfied_in_job",
                      value,
                    },
                    value !== option_choose
                  );
                }}
                select={form.applicant_satisfied_in_job}
                required
                error={errorFree.applicant_satisfied_in_job === false}
                errorMessage={t("form-required")}
              />
              {/* ------------------ /Satisfied in job ------------------ */}

              {/* -------------------- Short comment -------------------- */}
              <TextArea
                id="applicant_short_comment"
                label={t("form-applicant-step-5-short-comment")}
                placeholder={t("form-applicant-step-5-short-comment")}
                responseTextArea={(target, isOK) => {
                  handleChangeInput(target, isOK);
                }}
                defaultValue={form.applicant_short_comment}
                error={errorFree.applicant_short_comment === false}
                errorMessage={t("form-required")}
              />
              {/* -------------------- /Short comment -------------------- */}
            </Step>
          )}
        </form>
      </div>
    </>
  );
};

export default FormForApplicant;
