/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// ---------------------------------------------------------
import WebProvider from "./context/Context";
import Home from "./screens/home/Home";
import ForApplicants from "./screens/forApplicants/ForApplicants";
import ForCompanies from "./screens/forCompanies/ForCompanies";
import About from "./screens/about/About";
import Register from "./screens/register/Register";
import FormForApplicant from "./screens/formForApplicant/FormForApplicant";
import FormForCompany from "./screens/formForCompany/FormForCompany";
import DataProtection from "./screens/dataprotection/DataProtection";
import PreviewApplicants from "./screens/previewApplicants/PreviewApplicants";
import Error404 from "./screens/error404/Error404";
import Footer from "./components/footer/Footer";
// ---------------------------------------------------------

const App = () => {
  const brand = "CompanyU";

  return (
    <WebProvider>
      <Router basename="/">
        <Routes>
          <Route exact path="/" element={<Home docTitle={`${brand}`} />} />

          <Route
            exact
            path="/for-applicants"
            element={<ForApplicants docTitle={`${brand}`} />} /* Für Bewerber */
          />

          <Route
            exact
            path="/for-companies"
            element={
              <ForCompanies docTitle={`${brand}`} />
            } /* Für Unternehmen */
          />

          <Route
            exact
            path="/about"
            element={<About docTitle={`${brand}`} />} /* About und Impressum */
          />

          <Route
            exact
            path="/register"
            element={<Register docTitle={`${brand}`} />} /* Zur Registrierung */
          />

          <Route
            exact
            path="/register-applicant"
            element={
              <FormForApplicant docTitle={`${brand}`} />
            } /* Form for applicants */
          />

          <Route
            exact
            path="/register-company"
            element={
              <FormForCompany docTitle={`${brand}`} />
            } /* Form for company */
          />

          <Route
            exact
            path="/data-protection"
            element={
              <DataProtection docTitle={`${brand}`} />
            } /* Data Protection */
          />

          <Route
            exact
            path="/preview-applicants"
            element={
              <PreviewApplicants docTitle={`${brand}`} />
            } /* Preview Applicants */
          />

          <Route path="*" element={<Error404 docTitle={`${brand} `} />} />
        </Routes>

        <Footer />
      </Router>
    </WebProvider>
  );
};

export default App;
