/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./Home.css";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import { t } from "../../languages/settingsLanguages";
import Paragraph from "../../components/paragraph/Paragraph";
import BannerWithChildren from "../../components/bannerWithChildren/BannerWithChildren";
import Button from "../../components/button/Button";
import ContainerWithColumns from "../../components/containerWithColumns/ContainerWithColumns";
// ---------------------------------------------------------

const Home = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "hm" refer to the file: Home.css */}
      <Helmet>
        <title>{docTitle}</title>
      </Helmet>

      <Header />

      <Banner
        img="https://www.companyu.de/images/front_dark-1920w.jpg"
        alt="Banner Home"
        title={t("home-banner-title")}
        subtitle={t("home-banner-subtitle")}
        opacityDarkLayer={20}
      />

      <div className="hm_container">
        <Paragraph otherClassNameParagraph="hm_paragraph_center">
          <p>{t("home-p-world-changing")}</p>

          <p>{t("home-p-flexible")}</p>
        </Paragraph>

        <Paragraph otherClassNameParagraph="hm_paragraph_center">
          <p>{t("home-p-search-candidates")}</p>
        </Paragraph>

        <BannerWithChildren
          otherClassNameContainer="hm_banner_container"
          positionImg="right"
          img={require("../../assets/imgs/banner-home.jpeg")}
          alt="Work"
          opacityDarkLayer={70}
        >
          <div className="hm_banner_children_container">
            <Paragraph
              otherClassNameTitle="hm_banner_title_container"
              withTitle
              title={t("home-p-banner-info-title")}
            />

            <Paragraph otherClassNameParagraph="hn_banner_info">
              {t("home-p-banner-info")}
            </Paragraph>

            <Button type="link" link="/register" title={t("home-banner-btn")} />
          </div>
        </BannerWithChildren>

        <ContainerWithColumns otherClassNameContainer="hm_cols_container">
          <div>
            <Paragraph withTitle title={t("home-cols-info-applicants-title")}>
              <p>{t("home-cols-info-applicants-question")}</p>

              <p>{t("home-cols-info-applicants-no-problem-1")}</p>

              <p>{t("home-cols-info-applicants-no-problem-2")}</p>
            </Paragraph>

            <Paragraph
              withTitle
              title={t("home-cols-info-advantages-title")}
              otherClassNameTitle="hm_cols_subtitle"
              otherClassNameParagraph="hm_cols_li"
            >
              <ul>
                <li>
                  <p>{t("home-cols-info-adv-maximum-potential")}</p>
                </li>

                <li>
                  <p>{t("home-cols-ifo-adv-companies-recruit-you")}</p>
                </li>

                <li>
                  <p>{t("home-cols-ifo-adv-attractive-offers")}</p>
                </li>
              </ul>
            </Paragraph>
          </div>

          <div>
            <Paragraph withTitle title={t("home-cols-info-companies-title")}>
              <p>{t("home-cols-info-companies-you-take-control")}</p>

              <p>{t("home-cols-info-companies-fill-vacancies-1")}</p>

              <p>{t("home-cols-info-companies-fill-vacancies-2")}</p>
            </Paragraph>

            <Paragraph
              withTitle
              title={t("home-cols-info-advantages-title")}
              otherClassNameTitle="hm_cols_subtitle"
              otherClassNameParagraph="hm_cols_li"
            >
              <ul>
                <li>
                  <p>{t("home-cols-ifo-adv-choose-employee")}</p>
                </li>

                <li>
                  <p>{t("home-cols-ifo-adv-every-24-hours")}</p>
                </li>

                <li>
                  <p>{t("home-cols-ifo-adv-application-documents")}</p>
                </li>

                <li>
                  <p>{t("home-cols-ifo-adv-find-the-right-one")}</p>
                </li>
              </ul>
            </Paragraph>
          </div>
        </ContainerWithColumns>

        <div className="hm_horizontal_line" />

        <div className="hm_ourPatner_container">
          <p>{t("home-our-patner")}</p>

          <img
            src={require("../../assets/imgs/our-patner.png")}
            alt="Our patner"
            className="hm_ourPatner_img"
          />
        </div>
      </div>
    </>
  );
};

export default Home;
