/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
// ---------------------------------------------------------

const Reducers = (state, action) => {
  switch (action.type) {
    case "DEFAULT_TEST":
      return {
        ...state,
        defaultTest: action.payload,
      };

    case "CHANGE_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };

    default:
      return state;
  }
};

export default Reducers;
