/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./DataProtection.css";
import { t } from "../../languages/settingsLanguages";
import Header from "../../components/header/Header";
import Paragraph from "../../components/paragraph/Paragraph";
import Button from "../../components/button/Button";
// ---------------------------------------------------------

const DataProtection = ({ docTitle }) => {
  const DataLink = ({ link = "" }) => {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {link}
      </a>
    );
  };

  return (
    <>
      {/* Class names starting with "dp" refer to the file: ForApplicants.css */}
      <Helmet>
        <title>
          {docTitle} | {t("data-protection-title")}
        </title>
      </Helmet>

      <Header />

      <div className="dp_container">
        <Paragraph
          withTitle
          title={t("data-protection-title")}
          otherClassNameTitle="dp_title"
        />

        <Paragraph children={t("dp-1")} />

        <Paragraph withTitle title={t("dp-2-title")}>
          <p>Company U GbR</p>

          <p>St. Veit - Straße 9 27809 Lemwerder</p>

          <Button
            type="link"
            link="tel:4915161402225"
            title="Tel: +4915161402225"
            otherClassNameBtn="dp_link"
          />

          <Button
            type="link"
            link="mailto:info@companyu.de"
            title="Mail: info@companyu.de"
            otherClassNameBtn="dp_link"
          />
        </Paragraph>

        <Paragraph withTitle title={t("dp-3-title")}>
          {t("dp-3-1")}
        </Paragraph>

        <Paragraph withTitle title={t("dp-4-title")}>
          <p>{t("dp-4-1")}</p>

          <p>{t("dp-4-2")}</p>

          <p>{t("dp-4-3")}</p>

          <p>{t("dp-4-4")}</p>

          <p>{t("dp-4-5")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-5-title")}>
          <p>{t("dp-5-1")}</p>

          <p>{t("dp-5-2")}</p>

          <p>{t("dp-5-3")}</p>

          <p>{t("dp-5-4")}</p>

          <p>{t("dp-5-5")}</p>

          <p>{t("dp-5-6")}</p>

          <p>{t("dp-5-7")}</p>
        </Paragraph>

        <Paragraph otherClassNameContainer="dp_special_container">
          <p>{t("dp-5-special-1")}</p>

          <p>{t("dp-5-special-2")}</p>
        </Paragraph>

        <Paragraph>
          <p>{t("dp-5-8")}</p>

          <p>{t("dp-5-9")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-6-10-title")}>
          <p>{t("dp-6-1")}</p>

          <p>{t("dp-6-2")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-7-title")}>
          <p>{t("dp-7-1")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-8-title")}>
          <p>{t("dp-8-1")}</p>

          <p>{t("dp-8-2")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-9-title")}>
          <p>{t("dp-9-1")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-10-title")}>
          <p>{t("dp-10-1")}</p>

          <p>{t("dp-10-2")}</p>

          <p>{t("dp-10-3")}</p>

          <p>{t("dp-10-4")}</p>

          <p>{t("dp-10-5")}</p>

          <p>{t("dp-10-6")}</p>

          <p>
            {t("dp-10-7")} <DataLink link={"https://www.zendesk.de/"} />
          </p>

          <p>
            {t("dp-10-8-1")}{" "}
            <DataLink link="https://www.zendesk.de/company/privacy-and-data-protection/" />{" "}
            {t("dp-10-8-2")}{" "}
            <DataLink link="https://www.zendesk.de/company/legal/" />
          </p>

          <p>{t("dp-10-9")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-11-title")}>
          <p>{t("dp-11-1")}</p>

          <p>{t("dp-11-2")}</p>

          <p>{t("dp-11-3")}</p>

          <p>{t("dp-11-4")}</p>

          <p>{t("dp-11-5")}</p>

          <p>{t("dp-11-6")}</p>

          <p>
            {t("dp-11-7")}{" "}
            <DataLink link="https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop" />
          </p>

          <p>
            {t("dp-11-8")}{" "}
            <DataLink link="https://support.google.com/chrome/answer/95647?hl=de&hlrm=en" />
          </p>

          <p>
            {t("dp-11-9")}{" "}
            <DataLink link="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d" />
          </p>

          <p>
            {t("dp-11-10")}{" "}
            <DataLink link="https://support.apple.com/de-de/guide/safari/sfri11471/mac" />
          </p>

          <p>
            {t("dp-11-11")}{" "}
            <DataLink link="https://help.opera.com/de/latest/web-preferences/#cookies" />
          </p>

          <p>
            {t("dp-11-12")}{" "}
            <DataLink link="https://browser.yandex.com/help/personal-data-protection/cookies.html" />
          </p>

          <p>
            {t("dp-11-13-1")}{" "}
            <DataLink link="https://www.youronlinechoices.eu/" />{" "}
            {t("dp-11-13-2")}{" "}
            <DataLink link="https://optout.networkadvertising.org/?c=1" />
          </p>

          <p>{t("dp-11-14")}</p>

          <p>{t("dp-11-15")}</p>

          <p>
            {t("dp-11-16")}{" "}
            <DataLink link="https://support.mozilla.org/de/kb/wie-verhindere-ich-dass-websites-mich-verfolgen" />
          </p>

          <p>
            {t("dp-11-17")}{" "}
            <DataLink link="https://support.google.com/chrome/answer/2790761?co=GENIE.Platform%3DDesktop&hl=de" />
          </p>

          <p>
            {t("dp-11-18")}{" "}
            <DataLink link="https://support.microsoft.com/de-de/windows/verwenden-von-do-not-track-in-internet-explorer-11-ad61fa73-d533-ce96-3f64-2aa3a332e792" />
          </p>

          <p>
            {t("dp-11-19")}{" "}
            <DataLink link="https://help.opera.com/de/latest/security-and-privacy/" />
          </p>

          <p>
            {t("dp-11-20")}{" "}
            <DataLink link="https://support.apple.com/de-de/guide/safari/sfri40732/12.0/mac" />
          </p>

          <p>
            {t("dp-11-21")}{" "}
            <DataLink link="https://browser.yandex.com/help/personal-data-protection/ytp.html" />
          </p>

          <p>{t("dp-11-22")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-12-title")}>
          <p>{t("dp-12-1")}</p>

          <p>{t("dp-12-2")}</p>

          <p>{t("dp-12-3")}</p>

          <p>{t("dp-12-4")}</p>

          <p>{t("dp-12-5")}</p>

          <p>{t("dp-12-6")}</p>

          <p>{t("dp-12-7")}</p>

          <p>{t("dp-12-8")}</p>

          <p>
            {t("dp-12-9")}{" "}
            <DataLink link="https://mailchimp.com/de/?currency=EUR" />
          </p>

          <p>
            {t("dp-12-10")}{" "}
            <DataLink link="https://www.intuit.com/privacy/statement/" />
          </p>

          <p>{t("dp-12-11")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-13-title")}>
          <p>{t("dp-13-1")}</p>

          <p>{t("dp-13-2")}</p>

          <p>{t("dp-13-3")}</p>

          <p>{t("dp-13-4")}</p>

          <p className="dp_minisibtitle">{t("dp-13-5")}</p>

          <p>{t("dp-13-6")}</p>

          <p>
            {t("dp-13-7")}{" "}
            <DataLink link="https://www.google.com/intl/de_de/adsense/start/" />
          </p>

          <p>
            {t("dp-13-8")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-9-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-9-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-10")}</p>

          <p>{t("dp-13-11")}</p>

          <p>
            {t("dp-13-12")}{" "}
            <DataLink link="https://marketingplatform.google.com/intl/de/about/analytics/" />
          </p>

          <p>
            {t("dp-13-13")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/6004245?hl=de" />
          </p>

          <p>
            {t("dp-13-14-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-14-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-15")}</p>

          <p>{t("dp-13-16")}</p>

          <p>
            {t("dp-13-17")}{" "}
            <DataLink link="https://marketingplatform.google.com/about/analytics-360/" />
          </p>

          <p>
            {t("dp-13-18-1")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/6004245?hl=de" />{" "}
            {t("dp-13-18-2")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-19-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-19-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-20")}</p>

          <p>{t("dp-13-21")}</p>

          <p>
            {t("dp-13-22")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/10089681?hl=de" />
          </p>

          <p>
            {t("dp-13-23-1")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/6004245?hl=de" />{" "}
            {t("dp-13-23-2")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-24-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-24-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-25")}</p>

          <p>{t("dp-13-26")}</p>

          <p>
            {t("dp-13-27")}{" "}
            <DataLink link="https://marketingplatform.google.com/intl/de/about/optimize/" />
          </p>

          <p>
            {t("dp-13-28-1")}{" "}
            <DataLink link="https://support.google.com/analytics/answer/6004245?hl=de" />{" "}
            {t("dp-13-28-1")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-29-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-29-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-30")}</p>

          <p>{t("dp-13-31")}</p>

          <p>
            {t("dp-13-32")}{" "}
            <DataLink link="https://support.google.com/google-ads/answer/2453998" />
          </p>

          <p>
            {t("dp-13-33")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-34-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-34-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-35")}</p>

          <p>{t("dp-13-36")}</p>

          <p>
            {t("dp-13-37")}{" "}
            <DataLink link="https://marketingplatform.google.com/intl/de/about/search-ads-360/" />
          </p>

          <p>
            {t("dp-13-38")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-13-39-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-13-39-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-13-40")}</p>

          <p>{t("dp-13-41")}</p>

          <p>
            {t("dp-13-42")}{" "}
            <DataLink link="https://mailchimp.com/de/?currency=EUR" />
          </p>

          <p>
            {t("dp-13-43")}{" "}
            <DataLink link="https://www.intuit.com/privacy/statement/" />
          </p>

          <p>{t("dp-13-44")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-14-title")}>
          <p>{t("dp-14-1")}</p>

          <p>{t("dp-14-2")}</p>

          <p>{t("dp-14-3")}</p>

          <p className="dp_minisibtitle">{t("dp-14-4")}</p>

          <p>{t("dp-14-5")}</p>

          <p>
            {t("dp-14-6")} <DataLink link="https://www.facebook.com/" />
          </p>

          <p>
            {t("dp-14-7-1")}{" "}
            <DataLink link="https://developers.facebook.com/docs/plugins/" />{" "}
            {t("dp-14-7-2")}{" "}
            <DataLink link="https://de-de.facebook.com/help/568137493302217" />
          </p>

          <p>{t("dp-14-8")}</p>

          <p className="dp_minisibtitle">{t("dp-14-9")}</p>

          <p>{t("dp-14-10")}</p>

          <p>
            {t("dp-14-11")} <DataLink link="https://www.instagram.com/" />
          </p>

          <p>
            {t("dp-14-12-1")}{" "}
            <DataLink link="https://help.instagram.com/581066165581870" />{" "}
            {t("dp-14-12-2")}{" "}
            <DataLink link="https://help.instagram.com/519522125107875" />
          </p>

          <p>{t("dp-14-13")}</p>

          <p className="dp_minisibtitle">{t("dp-14-14")}</p>

          <p>{t("dp-14-15")}</p>

          <p>
            {t("dp-14-16")} <DataLink link="https://www.tiktok.com/" />
          </p>

          <p>
            {t("dp-14-17")}{" "}
            <DataLink link="https://www.tiktok.com/legal/privacy-policy?lang=de" />
          </p>

          <p>{t("dp-14-18")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-15-title")}>
          <p>{t("dp-15-1")}</p>

          <p>{t("dp-15-2")}</p>

          <p>{t("dp-15-3")}</p>

          <p className="dp_minisibtitle">{t("dp-15-4")}</p>

          <p>{t("dp-15-5")}</p>

          <p>
            {t("dp-15-6")} <DataLink link="https://www.fontawesome.com/" />
          </p>

          <p>
            {t("dp-15-7")} <DataLink link="https://fontawesome.com/privacy" />
          </p>

          <p className="dp_minisibtitle">{t("dp-15-8")}</p>

          <p>{t("dp-15-9")}</p>

          <p>
            {t("dp-15-10")} <DataLink link="https://fonts.google.com/" />
          </p>

          <p>
            {t("dp-15-11")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-15-12-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-15-12-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-15-13")}</p>

          <p>{t("dp-15-14")}</p>

          <p>
            {t("dp-15-15")} <DataLink link="https://openjsf.org/" />
          </p>

          <p>
            {t("dp-15-16")}{" "}
            <DataLink link="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf" />
          </p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-16-title")}>
          <p>{t("dp-16-1")}</p>

          <p>{t("dp-16-2")}</p>

          <p>{t("dp-16-3")}</p>

          <p className="dp_minisibtitle">{t("dp-16-4")}</p>

          <p>{t("dp-16-5")}</p>

          <p>
            {t("dp-16-6")} <DataLink link="https://www.google.de/maps" />
          </p>

          <p>
            {t("dp-16-7")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-16-8-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-16-8-2")}
          </p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-17-title")}>
          <p>{t("dp-17-1")}</p>

          <p>{t("dp-17-2")}</p>

          <p>{t("dp-17-3")}</p>

          <p>{t("dp-17-4")}</p>

          <p className="dp_minisibtitle">{t("dp-17-5")}</p>

          <p>{t("dp-17-6")}</p>

          <p>
            {t("dp-17-7")}{" "}
            <DataLink link="https://support.google.com/merchants/answer/7124326?hl=de" />
          </p>

          <p>
            {t("dp-17-8")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-17-9-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-17-9-2")}
          </p>

          <p className="dp_minisibtitle">{t("dp-17-10")}</p>

          <p>{t("dp-17-11")}</p>

          <p>
            {t("dp-17-12")}{" "}
            <DataLink link="https://www.trustedshops.de/bewertungen/" />
          </p>

          <p>
            {t("dp-17-13")}{" "}
            <DataLink link="https://www.trustedshops.de/impressum/#datenschutz" />
          </p>

          <p className="dp_minisibtitle">{t("dp-17-14")}</p>

          <p>{t("dp-17-15")}</p>

          <p>
            {t("dp-17-16")} <DataLink link="https://de.trustpilot.com/" />
          </p>

          <p>
            {t("dp-17-17")}{" "}
            <DataLink link="https://de.legal.trustpilot.com/end-user-privacy-terms" />
          </p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-18-title")}>
          <p>{t("dp-18-1")}</p>

          <p>{t("dp-18-2")}</p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-19-title")}>
          <p>{t("dp-19-1")}</p>

          <p>{t("dp-19-2")}</p>

          <p>{t("dp-19-3")}</p>

          <p className="dp_minisibtitle">{t("dp-19-4")}</p>

          <p>{t("dp-19-5")}</p>

          <p>
            {t("dp-19-6")} <DataLink link="https://www.google.com/recaptcha/" />
          </p>

          <p>
            {t("dp-19-7")}{" "}
            <DataLink link="https://policies.google.com/?hl=de" />
          </p>

          <p>
            {t("dp-19-8-1")}{" "}
            <DataLink link="https://business.safety.google/gdpr/" />{" "}
            {t("dp-19-8-2")}
          </p>
        </Paragraph>

        <Paragraph withTitle title={t("dp-20-title")}>
          <p>{t("dp-20-1")}</p>

          <p>{t("dp-20-2")}</p>
        </Paragraph>

        <Paragraph>
          <p>
            {t("dp-21-1-1")} <DataLink link="https://www.getlaw.de/" />{" "}
            {t("dp-21-1-2")}
          </p>
        </Paragraph>
      </div>
    </>
  );
};

export default DataProtection;
