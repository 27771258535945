/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./ForCompanies.css";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import { t } from "../../languages/settingsLanguages";
import Paragraph from "../../components/paragraph/Paragraph";
import BannerWithChildren from "../../components/bannerWithChildren/BannerWithChildren";
import Button from "../../components/button/Button";
// ---------------------------------------------------------

const ForCompanies = ({ docTitle }) => {
  return (
    <>
      {/* Class names starting with "fco" refer to the file: ForCompanies.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-companies")}
        </title>
      </Helmet>

      <Header />

      <Banner
        img="https://www.companyu.de/images/AdobeStock_5962892452-2880w.jpg"
        alt="Banner Companies"
        title={t("companies-banner-title")}
        subtitle={t("companies-banner-subtitle")}
        button={
          <Button
            type="link"
            link="/register-company"
            title={t("name-link-to-register")}
          />
        }
        button2={
          <Button
            type="link"
            link="/preview-applicants"
            title={t("name-link-preview-applicants")}
            otherClassNameBtn="fco_btn_preview"
          />
        }
        opacityDarkLayer={90}
      />

      <div className="fco_container">
        <Paragraph>
          <p>{t("companies-modern-method")}</p>

          <p>{t("companies-without-waiting")}</p>

          <p>{t("companies-invite-them")}</p>

          <p>{t("companies-efficiency")}</p>
        </Paragraph>
      </div>

      <div className="fco_main_container">
        <div className="fco_bg_img_container">
          <img
            src={require("../../assets/imgs/companies-bg-city.jpg")}
            alt="Bg conpanies"
            className="fco_bg_img_img"
          />

          <div className="fap_bg_img_darkLayer" />
        </div>

        <BannerWithChildren
          otherClassNameContainer="fco_banner_container"
          img={require("../../assets/imgs/companies-banner.jpeg")}
          alt="Banner companies"
        >
          <div className="fco_child_container">
            <Paragraph
              otherClassNameContainer="fco_child_paragraph_container"
              otherClassNameParagraph="fap_child_p"
              withTitle
              title={t("companies-banner-how-to-start-title")}
            >
              <p>{t("companies-banner-how-to-start-p")}</p>

              <p>1. {t("companies-banner-how-to-start-step-1")}</p>

              <p>2. {t("companies-banner-how-to-start-step-2")}</p>

              <p>3. {t("companies-banner-how-to-start-step-3")}</p>
            </Paragraph>

            <Button
              type="link"
              link="https://web.arbeitsagentur.de/entgeltatlas/"
              otherClassNameBtn="fco_banner_btn"
              title={t("applicants-banner-2-payment-atlas")}
            />
          </div>
        </BannerWithChildren>
      </div>
    </>
  );
};

export default ForCompanies;
