/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { API_BASE, path_register_company } from "../../api/apis";
// ---------------------------------------------------------
// ---------------------------------------------------------

const servicesFormCompany = async (
  data,
  response = (response = false) => {},
  loading = (state = false) => {}
) => {
  loading(true);
  const API = API_BASE;

  await fetch(`${API}${path_register_company}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-token": "",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.response === "successful") {
        console.log(res.response);
        response("successful");
        loading(false);
      }
    })
    .catch((error) => {
      console.log(error);
      response("failed");
      loading(false);
    });
};

export default servicesFormCompany;
