/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { createContext, useContext, useEffect, useReducer } from "react";
// ---------------------------------------------------------
import Reducers from "./Reducers";
import initialState from "./State";
import {
  defaultLanguage,
  setInitialLanguage,
} from "../languages/settingsLanguages";
// ---------------------------------------------------------

export const context = createContext();

export const WebContext = () => {
  const result_context = useContext(context);
  if (!context) throw new Error("There's no web context");

  return result_context;
};

const WebProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducers, initialState);

  useEffect(() => {
    setInitialLanguage(dispatch, defaultLanguage);
  }, []);

  return (
    <context.Provider
      value={{
        defaultTest: state.defaultTest,
        language: state.language,
        dispatch,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default WebProvider;
