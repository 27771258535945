/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
import "./Paragraph.css";
// ---------------------------------------------------------

const Paragraph = ({
  otherClassNameContainer = "",
  otherClassNameTitle = "",
  otherClassNameParagraph = "",
  withTitle = false,
  title = "",
  children = <></>,
}) => {
  return (
    <>
      {/* Class names starting with "pph" refer to the file: Paragraph.css */}
      <div className={`pph_container ${otherClassNameContainer}`}>
        {withTitle && (
          <h3 className={`pph_title ${otherClassNameTitle}`}>{title}</h3>
        )}

        <div className={`pph_paragraph_container ${otherClassNameParagraph}`}>
          {typeof children === "string" ? <p>{children}</p> : <>{children}</>}
        </div>
      </div>
    </>
  );
};

export default Paragraph;
