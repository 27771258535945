/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

import { Helmet } from "react-helmet";
// ---------------------------------------------------------
import "./About.css";
import Header from "../../components/header/Header";
import { t } from "../../languages/settingsLanguages";
import Banner from "../../components/banner/Banner";
import Paragraph from "../../components/paragraph/Paragraph";
import ContainerWithColumns from "../../components/containerWithColumns/ContainerWithColumns";
import Button from "../../components/button/Button";
// ---------------------------------------------------------

const About = ({ docTitle }) => {
  const AboutUs = () => {
    return (
      <div className="abt_container">
        <Paragraph otherClassNameParagraph="abt_paragraph">
          {t("about-us-p-1")}
        </Paragraph>

        <Paragraph otherClassNameParagraph="abt_paragraph">
          {t("about-us-p-2")}
        </Paragraph>

        <Paragraph otherClassNameParagraph="abt_paragraph">
          {t("about-us-p-3")}
        </Paragraph>
      </div>
    );
  };

  return (
    <>
      {/* Class names starting with "abt" refer to the file: About.css */}
      <Helmet>
        <title>
          {docTitle} | {t("name-link-about")}
        </title>
      </Helmet>

      <Header />

      <Banner
        width="100%"
        height="80rem"
        img={require("../../assets/imgs/applicants-bg-city.jpg")}
        alt="Bg about us"
        title={t("about-us-title")}
        subtitle={<AboutUs />}
        opacityDarkLayer={"d9"}
      />

      <div className="abt_imprint_container">
        <Paragraph
          otherClassNameTitle="abt_imprint_title"
          withTitle
          title={t("about-us-imprint-title")}
        />

        <ContainerWithColumns>
          <div>
            <Paragraph
              otherClassNameTitle="abt__imprint_texts_title"
              otherClassNameParagraph="abt__imprint_texts_p"
              withTitle
              title={t("about-us-imprint-service-provider-title")}
            >
              <p>{t("about-us-imprint-service-provider-p-1")}</p>

              <p>{t("about-us-imprint-service-provider-p-2")}</p>

              <p>{t("about-us-imprint-service-provider-p-3")}</p>
            </Paragraph>
          </div>

          <div>
            <Paragraph
              otherClassNameTitle="abt__imprint_texts_title"
              otherClassNameParagraph="abt__imprint_texts_p"
              withTitle
              title={t("about-us-imprint-contact-information-title")}
            >
              <p>{t("about-us-imprint-contact-information-p-1")}</p>

              <Button
                otherClassNameBtn="abt__imprint_texts_link"
                type="link"
                target="_blank"
                link="tel:+4917662566973"
                title="+4917662566973"
              />

              <Button
                otherClassNameBtn="abt__imprint_texts_link"
                type="link"
                target="_blank"
                link="mailto:info@companyu.de"
                title="info@companyu.de"
              />

              <p>{t("about-us-imprint-service-provider-p-3")}</p>
            </Paragraph>
          </div>

          <div>
            <Paragraph
              otherClassNameTitle="abt__imprint_texts_title"
              otherClassNameParagraph="abt__imprint_texts_p"
              withTitle
              title={t("about-us-imprint-identification-number-title")}
            >
              <p>{t("about-us-imprint-identification-number-p-1")}</p>
            </Paragraph>
          </div>

          <div>
            <Paragraph
              otherClassNameTitle="abt__imprint_texts_title"
              otherClassNameParagraph="abt__imprint_texts_p"
              withTitle
              title={t("about-us-imprint-conflict-resolution-title")}
            >
              <p>{t("about-us-imprint-conflict-resolution-p-1")}</p>

              <Button
                otherClassNameBtn="abt__imprint_texts_link"
                type="link"
                target="_blank"
                link="https://www.instagram.com/companyu_/"
                title="https://www.instagram.com/companyu_/"
              />

              <Button
                otherClassNameBtn="abt__imprint_texts_link"
                type="link"
                target="_blank"
                link="https://www.tiktok.com/@companyu.de"
                title="https://www.tiktok.com/@companyu.de"
              />

              <Button
                otherClassNameBtn="abt__imprint_texts_link"
                type="link"
                target="_blank"
                link="https://www.facebook.com/profile.php?id=61550685537067"
                title="https://www.facebook.com/profile.php?id=61550685537067"
              />

              <Button
                otherClassNameBtn="abt__imprint_texts_link"
                type="link"
                target="_blank"
                link="https://twitter.com/CompanyU_de"
                title="https://twitter.com/CompanyU_de"
              />
            </Paragraph>
          </div>
        </ContainerWithColumns>
      </div>
    </>
  );
};

export default About;
