/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// -----------------------------------------------------------------
import Esp from "./es.json";
import Eng from "./en.json";
import Deu from "./de.json";
import { WebContext } from "../context/Context";
import { changeLanguage } from "../context/Actions";
// -----------------------------------------------------------------

export const languagesResources = { Esp, Eng, Deu };
export const _languages = [
  {
    name: "Deutsch",
    shortName: "Deu",
    flag: "Your path to the flag image (with require or with https)",
  },
  {
    name: "English",
    shortName: "Eng",
    flag: "Your path to the flag image (with require or with https)",
  },
  {
    name: "Español",
    shortName: "Esp",
    flag: "Your path to the flag image (with require or with https)",
  },
];
export const defaultLanguage = _languages[0].shortName;

export const t = (keyText = "") => {
  const { language } = WebContext();

  const t =
    languagesResources[language][keyText] !== undefined
      ? languagesResources[language][keyText]
      : languagesResources[defaultLanguage][keyText] !== undefined
      ? languagesResources[defaultLanguage][keyText]
      : keyText;

  return t;

  /* 
To use the texts with language change you must do so with the following syntax:
<p>{t("test")}</p>
Where you must import the "t" function in each file where you use the texts stored in the keys of each language .json file in the "languages" folder.

Remember that all text keys for one language must be exactly the same keys in each of the other language .json files.

Example:

File Test.jsx:

import { t } from "../languages/settingsLanguages";

const Test = () => {
  return (
    <p>{t("test")}</p> // "test" is key and "t" return: "Hola" in Spanish and "Hello" in English
  )
}

----------------

File es.json:
{
  "test": "Hola"  // "test" is key
}

----------------

File en.json:
{
  "test": "Hello"  // "test" is key
}

------------------------------------------

Use the following syntax for keys:

default-test
test

Do not use the following syntax for keys:
default test
defaultTest
 */
};

export const setInitialLanguage = (dispatch, initLng = defaultLanguage) => {
  let prefLng = localStorage.getItem("lng_app");

  if (prefLng === null) {
    changeLanguage(dispatch, initLng);
  } else {
    changeLanguage(dispatch, prefLng);
  }

  return;
};
