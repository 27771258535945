/**
 * COMPANYU
 * desarrollo@companyu.de
 * @flow
 */

// ---------------------------------------------------------
// ---------------------------------------------------------

export const setDefaultTest = async (dispatch, defaultTest) => {
  dispatch({ type: "DEFAULT_TEST", payload: defaultTest });
};

export const changeLanguage = (dispatch, lng) => {
  localStorage.setItem("lng_app", lng);
  dispatch({ type: "CHANGE_LANGUAGE", payload: lng });
  return;
};
